export default {
    path: '',
    component: () => import(`@/page/distributor/component/basic-layout/1.0.4/index.vue`),
    children: [
        {
            path: '/flight-notice-list',
            name: 'flight-notice-list',
            component: () => import(/* webpackChunkName: "page-distributor-air-ticket-notice-list" */ './index.vue'),
        }
    ],
};
